<div class="modal-header">
    <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
    <h4 class="modal-title">View Onboarding Form Details</h4>
    <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>

<div class="modal-body">
    <h3 class="text-center">{{ job.on_boarding_form.name }}</h3>
    <div class="row">
        <div *ngFor="let field of fields.controls" class="col-md-12">
            <label class="form-label mt-4">{{ field.get('label')?.value }}{{ field.get('is_required')?.value ? '*' : '' }}</label>
            <ng-container *ngIf="field.get('is_attachment')?.value">
                <div *ngIf="isPdf(field.get('value')?.value)" class="pdf-container">
                    <div class="d-flex align-items-center">
                        <div class="me-2">
                            <img class="imageIcon" *ngIf="getFileIcon(field.get('value')?.value)" [src]="getFileIcon(field.get('value')?.value)" />
                    
                        </div>
                        <div class="flex-grow-1">
                            <label>{{ field.get('value')?.value }}</label>
                        </div>
                        <div class="ms-auto">
                            <button (click)="downloadDocument(field.get('attachment')?.value, field.get('value')?.value)" class="btn btn-sm mx-2 btn-success">
                                <i class="bi bi-cloud-arrow-down-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <img *ngIf="isImage(field.get('value')?.value)" [src]="getImageSrc(field.get('attachment')?.value)" alt="Attachment" class="attachment-img d-flex align-items-cente">
            </ng-container>
            <input type="text" *ngIf="!field.get('is_attachment')?.value" class="form-control" [value]="field.get('value')?.value || 'Value not entered by candidate'" disabled>
        </div>
        <div *ngIf="signedContract" class="col-md-12 mt-4">
            <div>
                <label class="form-label">Uploaded Contract:</label>
                <div *ngIf="signedContract.name" class="d-flex align-items-center">
                    <div class="me-2">
                        <img class="imageIcon" *ngIf="getFileIcon(signedContract.name)" [src]="getFileIcon(signedContract.name)" />
                    </div>
                    <div class="flex-grow-1">
                        <label>{{ signedContract.name }}</label>
                    </div>
                    <div class="ms-auto">
                        <button (click)="downloadDocument(signedContract.original_doc_contents, signedContract.name)" class="btn btn-sm mx-2 btn-success">
                            <i class="bi bi-cloud-arrow-down-fill"></i>
                        </button>
                    </div>
                </div>
            </div>
            
            <div>
                <label class="form-label">Signed Contract:</label>
                <div *ngIf="signedContract.submit_name" class="d-flex align-items-center">
                    <div class="me-2">
                        <img class="imageIcon" *ngIf="getFileIcon(signedContract.submit_name)" [src]="getFileIcon(signedContract.submit_name)" />             
                    </div>
                    <div class="flex-grow-1">
                        <label>{{ signedContract.submit_name }}</label>
                    </div>
                    <div class="ms-auto">
                        <button (click)="downloadDocument(signedContract.submit_doc_contents, signedContract.submit_name)" class="btn btn-sm mx-2 btn-success">
                            <i class="bi bi-cloud-arrow-down-fill"></i>
                        </button>
                    </div>
                </div>
                <div *ngIf="!signedContract.submit_name" class="col-md-12 mt-2">
                    <label class="form-label text-danger">Signed contract not uploaded by candidate.</label>
                </div>
            </div>

            
        </div>
        
    </div>
</div>
<div class="modal-footer">
    <div class="error" *ngIf="error">
        <span>{{ error }}</span>
    </div>
    <button type="button" class="btn btn-sm btn-neutral mx-2" (click)="onClose()"> Cancel</button>
    <ng-container *ngIf="user?.role_uuid === ROLES?.AGENCY && (job.status === 3 || job.status === 4 || job.status === 6)">
        <button type="button" class="btn btn-sm mx-2 btn-primary" (click)="approveJob(job.uuid)">Approve</button>
        <button *ngIf="job.status == 3 || job.status === 6" type="button" class="btn btn-sm mx-2 btn-danger" (click)="rejectJob(job.uuid)">Reject</button>
        <button *ngIf="job.status == 3 || job.status === 6" type="button" class="btn btn-sm mx-2 btn-danger" (click)="permanentRejectJob(job.uuid)">Permanent Reject</button>
    </ng-container>
    
</div>


