import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { AlertModal, SuccessModal } from 'src/app/app.model';
import { AfterViewChecked, AfterViewInit, Component, OnInit, Input, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { OnBoardingFormService } from 'src/app/services/on-boarding-form.service';
import { SuccessModalComponent } from '../success-modal/success-modal.component';

@Component({
  selector: 'app-submit-onboarding-form-details',
  templateUrl: './submit-onboarding-form-details.component.html',
  styleUrls: ['./submit-onboarding-form-details.component.css']
})

export class SubmitOnboardingFormDetailsComponent implements OnInit {
  @Input() formFields: any;
  @Input() contract: any;  
  @Input() assignmentUuid: string;
  @Input() formDetails: any;
  @Input() isEdit: boolean = false;
  @Input() submitContract: boolean;
  @Input() assignmentStatus: number;
  isInputFieldDisabled: boolean = false;
  form: FormGroup | any;
  error!: string;
  loading: boolean = false;
  isContractUploaded: boolean = false;
  formFileInputs: { [key: string]: File | null } = {};
  acceptFiles: any = [
    '.pdf', '.png', '.svg', '.jpeg', '.jpg', '.docx', '.doc'
  ]
  acceptContractFiles: any = [
    '.pdf', '.docx', '.doc'
  ]

  iconList = [
    { type: 'docs', icon: 'assets/extIcons/icons8-microsoft-word-48.png' },
    { type: 'docx', icon: 'assets/extIcons/icons8-microsoft-word-48.png' },
    { type: 'pdf', icon: 'assets/extIcons/icons8-pdf-48.png' },
    { type: 'png', icon: 'assets/extIcons/icons8-png-48.png' },
  ]
  
  constructor(
    private activeModalService: NgbActiveModal,
    private onBoardingFormService: OnBoardingFormService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    if(this.assignmentStatus === 2) {
      this.isInputFieldDisabled;
    } else {
      this.isInputFieldDisabled = true;
    }
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({});
    this.formFields.forEach((field: any) => { 
      const validators = field.is_required ? [Validators.required] : [];
      this.form.addControl(field.field_uuid, this.fb.control('', validators));
      if (field.type === 'text' || field.type === 'number' || field.type === 'textarea' || field.type === 'dropdown') {
        // Check if field.values is defined and not null before setting the value
        if (field && field.value !== null) {
          this.form.get(field.field_uuid)?.setValue(field.value);
        }
      }

     
    });
  }

  getFieldErrorId(fieldName: string): string {
    return `${fieldName}-error`;
  }

  getImageSrc(base64Data: string): string {
    return `data:image/png;base64,${base64Data}`;
  }

  isPdf(fileName: string): boolean {
    const extension = fileName.split('.').pop()?.toLowerCase();
    return extension === 'pdf' || extension === 'docx' || extension === 'doc';
  }

  isImage(fileName: string): boolean {
    const extension = fileName.split('.').pop()?.toLowerCase();
    return extension === 'png' || extension === 'jpg' || extension === 'jpeg' || extension === 'gif';
  }

  getFileType(fileName: string){
    return fileName.split('.')[fileName.split('.').length-1];
  }

  getFileIcon(attachment: any) {
    const fileType = this.getFileType(attachment);
    return this.iconList.find(x => x.type === fileType)?.icon
  }

  onFileChange(event: any, controlName: string) {
    
    const file = event.target.files[0]; 
    this.formFileInputs[controlName] = file;
    this.isContractUploaded = true;
  }

  downloadDocument(name: any, contents: any) {
    console.table(contents, name);
    const a = document.createElement('a');
    a.href = "data:image/png;base64," + contents;
    a.setAttribute('download', name)
    a.click();
  }

  onKeyPress(event: KeyboardEvent) {
    const allowedChars = /[0-9]/;
    const key = event.key;
    if (!allowedChars.test(key)) {
        event.preventDefault();
    }
}


  get hasValue() {
    let hasValue = false;
    Object.keys(this.form.value).forEach(element => {
      if (this.form.value[element]) {
        hasValue = true;
      }
    });
    return hasValue
  }

  onClose() {
    if (!this.hasValue) {
      this.activeModalService.close();
      return
    }
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to discard form updation?',
      yesAction: 'Discard',
      noAction: 'Cancel',
      yesActionColor: '#0566EA',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  isSaveEnabled(): boolean {
    const fieldsAvailable = this.formFields.fields.length > 0;
    const contractRequired = this.formFields.document;

    return fieldsAvailable || !contractRequired;
  }
  onSubmit() {
    if (this.form.valid) {
      this.loading = true;

      const formFieldValues = this.form.value;
      const formData = new FormData(); 
      const fields = [];

      for (const key in formFieldValues) {
        if (formFieldValues.hasOwnProperty(key)) {
            if (this.formFileInputs.hasOwnProperty(key)) {
                delete formFieldValues[key];
            } else if (!(formFieldValues[key] instanceof File)) {
                fields.push({ field_uuid: key, field_value: formFieldValues[key] });
            }
        }
      }
      formData.append('fields', JSON.stringify(fields));

      for (const name in this.formFileInputs) {
        if (this.formFileInputs.hasOwnProperty(name)) {
          const file = this.formFileInputs[name];
          if (file instanceof File) {
            formData.append(name, file);
          }
        }
      }
      
      if (this.isEdit === true) {
        this.onBoardingFormService.updateOnBoardingFormFieldValues(formData, this.assignmentUuid)
        .subscribe(
          res => {
            this.successModal(this.isInputFieldDisabled ? 'Contract Submitted Successfully.' : 'Onboarding Form Details Resubmitted Successfully.');
            this.activeModalService.close({ refresh: true });
            this.loading = false;
          }, 
          error => {
            this.loading = false;
            this.error = error;
          }
        );
        this.loading = false;
      } else {
        this.onBoardingFormService.submitOnBoardingFormFieldValues(formData, this.assignmentUuid)
        .subscribe(
          res => {
            this.successModal(res.message ? res.message : 'Onboarding Form Details Submitted Successfully.');
            this.activeModalService.close({ refresh: true });
            this.loading = false;
          }, 
          error => {
            this.loading = false;
            this.error = error;
          }
        );
        this.loading = false;
        this.form.reset();
      }
    } else {
      this.error = 'Please fill in all required fields correctly.';
    }
  }
}

