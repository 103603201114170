import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apiConstant } from '../app.constant';
import { OnBoardingForms } from '../app.model';
import { SubmitOnBoardingForms } from '../app.model';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OnBoardingFormService {

  private activeTabSubject = new BehaviorSubject<string>('onboarding');
  activeTab$ = this.activeTabSubject.asObservable();

  constructor(private http: HttpClient) { }

  setActiveTab(tab: string) {
    this.activeTabSubject.next(tab);
  }

  getOnBoardingForms(params: any) {
    let request_url = `${environment.baseUrl + apiConstant.ONBOARDING_FORM}?page=${params?.page}`;

    if (params?.limit) {
      request_url += `&per_page=${params?.limit}`;
    }

    return this.http.get<OnBoardingForms>(request_url);
  }
  
  submitOnBoardingForm(payload: any, formUuid: string) {
      return this.http.post<OnBoardingForms>(`${environment.baseUrl}` + apiConstant.ONBOARDING_FORM, payload);
  }

  updateOnBoardingForm(payload: any, formUuid: string) {
      return this.http.post<OnBoardingForms>(`${environment.baseUrl + apiConstant.ONBOARDING_FORM_UPDATE}/` + formUuid, payload);
  }

  submitOnBoardingFormFieldValues(payload: any, jobAssignmentUuid: string) {
    return this.http.post<SubmitOnBoardingForms>(`${environment.baseUrl + jobAssignmentUuid}/` + apiConstant.SUBMIT_ONBOARDING_FORM, payload);
  }

  updateOnBoardingFormFieldValues(payload: any, jobAssignmentUuid: string) {
    return this.http.post<SubmitOnBoardingForms>(`${environment.baseUrl + jobAssignmentUuid}/` + apiConstant.UPDATE_ONBOARDING_FORM, payload);
  }

  getOnBoardingFormData(formUuid: string) {
    return this.http.get<OnBoardingForms>(`${environment.baseUrl + apiConstant.ONBOARDING_FORM}/` + formUuid);

  }
}
