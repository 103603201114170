import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-job-reject-reason',
  templateUrl: './view-job-reject-reason.component.html',
  styleUrls: ['./view-job-reject-reason.component.css']
})
export class ViewJobRejectReasonComponent implements OnInit {
  @Input() reason: any;

  constructor(
    public activeModalService: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  onClose() {
    this.activeModalService.close();
   
  }

}
