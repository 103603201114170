import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiConstant } from '../app.constant';
import { Job } from '../app.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private http: HttpClient) { }

  getJobs(page: number, search?:string, filters: any = []) {
    let url = `${environment.baseUrl+apiConstant.JOBS}?page=${page}`;

    if(search != undefined) {
      url = url + `&search=${search}`;
    }

    if(filters.active != undefined) {
      url+= '&filters[active]='+filters.active;
    }
    
    return this.http.get<Job>(url);
  }

  getJobsWithSearch(search: string) {
    return this.http.get<Job>(`${environment.baseUrl+apiConstant.JOBS}?search=${search}`);
  }

  getJob(jobUuid: string) {
    return this.http.get<Job>(`${environment.baseUrl+apiConstant.JOBS}/${jobUuid}`);
  }

  deleteJob(jobId: any) {
    return this.http.delete<Job>(`${environment.baseUrl + apiConstant.JOBS}/` + jobId, {});
  }

  createJob(payload: any) {
    return this.http.post<Job>(`${environment.baseUrl}` + apiConstant.JOBS, payload);
  }

  updateJob(jobUuid: string, payload:any) {
    return this.http.post<Job>(`${environment.baseUrl + apiConstant.JOBS}/` + jobUuid, payload);
  }
  
  deactivateJob(jobUuid: string) {
    return this.http.post<Job>(`${environment.baseUrl + apiConstant.JOBS + '/' +jobUuid}/`+ apiConstant.DEACTIVATE , {});
  }

  activateJob(jobUuid: string) {
    return this.http.post<Job>(`${environment.baseUrl + apiConstant.JOBS +  '/' + jobUuid}/`+ apiConstant.ACTIVATE , {});
  }
 
  approveJob(jobUuid:string) {
    return this.http.post<Job>(`${environment.baseUrl + apiConstant.JOBS +  '/'+ jobUuid}/`+ apiConstant.APPROVE , {});
  }

  rejectJob(jobUuid:string, payload:any) {
    return this.http.post<Job>(`${environment.baseUrl + apiConstant.JOBS +  '/'+ jobUuid}/`+ apiConstant.REJECT , payload);
  }

  permanentRejectJob(jobUuid:string, payload:any) {
    return this.http.post<Job>(`${environment.baseUrl + apiConstant.JOBS +  '/'+ jobUuid}/`+ apiConstant.PERMANENT_REJECT , payload);
  }
}
