<div class="modal-header">
    <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
    <h4 class="modal-title">Reason</h4>
    <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>


<div class="modal-body">
    <div class="row gx-4">
        <div class="col-md-12">
            <textarea class="form-control" id="reason" name="reason" readonly>{{reason}}</textarea>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onClose()">Close</button>
</div>   
