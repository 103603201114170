<div class="loader" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
<div class="modal-header">
    <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
    <h4 class="modal-title">Provide Reason for Job Rejection</h4>
    <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>


<div class="modal-body">
    <div class="row gx-4">
        <div class="col-md-12">
            <label for="reason" class="mb-2">Reason:</label>
            <textarea class="form-control" id="reason" [(ngModel)]="reason" name="reason" required></textarea>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="error" *ngIf="error">
        <span>{{error}}</span>
    </div>
    <button type="button" class="btn btn-sm btn-neutral mx-2" (click)="onClose()"> Cancel</button>
    <button type="submit" class="btn btn-sm mx-2 btn-primary" (click)="reject()" [disabled]="!reason">Submit</button>
</div>   
