import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateFormComponent } from '../components/create-form/create-form.component';
import { TimesheetService } from '../services/timesheet.service';
import { OnBoardingFormService } from '../services/on-boarding-form.service';

@Component({
  selector: 'app-timesheet-forms',
  templateUrl: './timesheet-forms.component.html',
  styleUrls: ['./timesheet-forms.component.css']
})
export class TimesheetFormsComponent implements OnInit {
  timesheets: any = [];
  sortedForms: any = [];
  forms: any = [];
  onBoardingForms: any = [];
  allBoardingForm: any = [];
  loading: boolean = false;
  redirectedName!: string;
  totalCount: number = 0;

  activeTab: string = 'onboarding';

  constructor(
    private timesheetService: TimesheetService,
    private onBoardingFormService: OnBoardingFormService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit(page = 1): void {
    this.getForms(); 

    this.route.queryParams.subscribe((params: any) => {
      this.redirectedName = params?.name;
      
      this.getOnBoardingForm(page);
    });

    this.onBoardingFormService.activeTab$.subscribe(tab => {
      this.activeTab = tab;
    });
  }

  activateTab(tab: string) {
    this.onBoardingFormService.setActiveTab(tab);
  }

  async getForms() {
    this.sortedForms = [];
    this.forms = [];
    this.loading = true;
    this.forms = await new Promise((resolve) => this.timesheetService.getTimesheetForm().subscribe((res: any) => resolve(res.data)));
    this.loading = false;
    this.sortedForms = this.forms.slice();
  }

  async getOnBoardingForm(page: number, search?: string) {
    this.loading = true;

    const params: any = {
      search,
      page,
      limit: 20,
      order: 'desc',
      order_by: 'created_at',
    };

    this.onBoardingForms = []
    this.allBoardingForm = []
    this.onBoardingFormService.getOnBoardingForms(params).subscribe(onBoardingForms => {
      this.onBoardingForms = onBoardingForms.data;
      this.totalCount = onBoardingForms.meta.total;

      if (this.redirectedName) {
        this.onBoardingForms = this.onBoardingForms.filter((x: any) => x.name === this.redirectedName);
      }
      this.loading = false;
      this.allBoardingForm = this.onBoardingForms.slice();
    });
  }

  onStepChange(event: any) {
    this.ngOnInit(event)
  }

  isTimesheetTabActive(): boolean {
    return this.route.snapshot.fragment === 'timesheet-tab';
  }

  isOnboardingTabActive(): boolean {
    return this.route.snapshot.fragment === 'onboarding-tab';
  }

  sortData(sort: Sort) {
    const data = this.forms.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedForms = data;
      return;
    }

    this.sortedForms = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'count':
          return compare(a.fields.length, b.fields.length, isAsc);
        default:
          return 0;
      }
    });
  }

  sortOnboardingData(sort: Sort) {
    const data = this.onBoardingForms.slice();
    console.log('Original data:', data);
    if (!sort.active || sort.direction === '') {
      this.allBoardingForm = data;
      return;
    }
  
    this.allBoardingForm = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'count': 
          return compare(a.fields.length, b.fields.length, isAsc);
        default:
          return 0;
      }
    });

    console.log('Sorted data:', this.onBoardingForms); 
  }

  onAdd(edit: boolean, preview?: boolean, formData?: string) {
    if (edit) {
      this.router.navigate(['create-form'], { queryParams: { preview, formData: JSON.stringify(formData) } })
    } else {
      this.router.navigate(['create-form'], { queryParams: { preview } })
    }
  }

  navigateToEditForm(formUuid: string) {
    this.router.navigate(['/edit-form', formUuid]); // navigate to edit form component
  }

  onBoardFormAdd(edit: boolean, preview?: boolean, formData?: string, formId?: string) {
    if (edit) {
      this.router.navigate(['/on-boarding-form/create'], { queryParams: { preview, formData: formId } })
    } else {
      this.router.navigate(['/on-boarding-form/create'], { queryParams: { preview } })
    }
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}