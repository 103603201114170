import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnBoardingFormService } from 'src/app/services/on-boarding-form.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-view-on-boarding-form',
  templateUrl: './view-on-boarding-form.component.html',
  styleUrls: ['./view-on-boarding-form.component.css']
})
export class ViewOnBoardingFormComponent implements OnInit {
  formUuid: string;
  formName!: string;
  error = '';
  fields: any = [
    { is_required: 0, type: 'text', order: 7, label: '' }
  ]
  preview: boolean = false;
  formData: any;
  formCount: any = 0;
  previewData: any = {};
  contractCheckbox: boolean = false;
  showFileField: boolean = false;
  selectedFile: File | null = null;
  iconList = [
    { type: 'doc', icon: 'assets/extIcons/icons8-microsoft-word-48.png' },
    { type: 'docx', icon: 'assets/extIcons/icons8-microsoft-word-48.png' },
    { type: 'pdf', icon: 'assets/extIcons/icons8-pdf-48.png' },
  ]


  constructor(
    private route: ActivatedRoute,
    private onBoardingFormService: OnBoardingFormService,
    private location: Location,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.formUuid = params['uuid']; 
      this.getFormData(this.formUuid); 
    });
  }

  getFormData(uuid: string) {
    this.onBoardingFormService.getOnBoardingFormData(uuid).subscribe(res => {
        this.formData = res.data; 
        this.previewData = { 
          form_name: this.formData.name,
          fields: this.formData.fields,
          
        };
  
        if (this.formData?.document) {
          this.contractCheckbox = true; 
          this.showFileField = true;
          this.selectedFile = this.formData.document; 
        }
    });
  }

  getFileType(fileName: string){
    return fileName.split('.')[fileName.split('.').length-1];
  }

  getFileIcon(attachment: any) {
    const fileType = this.getFileType(attachment);
    return this.iconList.find(x => x.type === fileType)?.icon
  }

  close() {
    this.router.navigate(['/settings/form']);
  }

}
