<div class="container-fluid max-w-screen-md vstack gap-6 mt-4">
    <!-- Tabs -->
    <ul class="nav nav-tabs" id="myTabs" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link" [class.active]="activeTab === 'onboarding'"
            (click)="activateTab('onboarding')" id="onboarding-tab" data-bs-toggle="tab" data-bs-target="#onboarding"
            type="button" role="tab" aria-controls="onboarding" aria-selected="false">Onboarding Forms</button>
        </li>

        <li class="nav-item" role="presentation">
            <button class="nav-link" [class.active]="activeTab === 'timesheet'"
            (click)="activateTab('timesheet')" id="timesheet-tab" data-bs-toggle="tab" data-bs-target="#timesheet"
            type="button" role="tab" aria-controls="timesheet" aria-selected="true">Timesheet Forms</button>
        </li>
    </ul>

 


    <!-- Tab panes -->
    <div class="tab-content">
        <!-- Timesheet Forms Tab -->
        <div class="tab-pane fade " id="timesheet" role="tabpanel" aria-labelledby="timesheet-tab" [class.show]="activeTab === 'timesheet'" [class.active]="activeTab === 'timesheet'">
            <div class="card">
                <div class="card-body">
                    <!-- Content for Timesheet Forms -->
                    <div class="loader" *ngIf="loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </div>
                    <div class="reminder mb-0 d-flex bg-light justify-content-between">
                        <h5 class="card-title ps-4 mb-0 tableTitle">Timesheet Forms</h5>
                        <div class="submitForm d-flex justify-content-center align-items-center"
                            *ngIf="!loading && sortedForms.length < 1">
                            <button class="submitForm btn btn-sm px-3 mx-5 btn-neutral d-flex align-items-center text-white"
                                (click)="onAdd(false, false)">
                                <i id="add" class="bi bi-plus-circle-dotted me-2"></i>
                                Add New
                            </button>
                        </div>
                    </div>
                    <div class="">
                        <table matSort (matSortChange)="sortData($event)" class="table table-hover table-nowrap"
                            id="setting_forms_tables">
                            <thead class="table-light">
                                <tr>
                                    <th mat-sort-header="name" scope="col">Name</th>
                                    <th mat-sort-header="count" scope="col">Fields count</th>
                                    <th class="text-end" scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let form of sortedForms; let i = index" class="dataColumn">
                                    <td class="text-heading font-semibold">
                                        <a class="text-heading font-semibold" href="javascript:void(0);"
                                            (click)="onAdd(true, true, form)">
                                            {{form.name}}
                                        </a>
                                    </td>
                                    <td>{{form.fields.length}}</td>
                                    <td class="text-end">
                                        <div class="dropdown">
                                            <a class="text-muted" href="#" role="button" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <i class="bi bi-three-dots-vertical"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end">
                                                <a class="dropdown-item" matTooltip="Edit"
                                                    (click)="onAdd(true, false, form)">
                                                    Edit
                                                </a>
                                                <a class="dropdown-item" matTooltip="preview"
                                                    (click)="onAdd(true, true, form)">
                                                    View
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- Onboarding Forms Tab -->
        <div class="tab-pane fade " id="onboarding" role="tabpanel" aria-labelledby="onboarding-tab" [class.show]="activeTab === 'onboarding'" [class.active]="activeTab === 'onboarding'">
            <!-- Content for Onboarding Forms -->
            <div class="card">
                <div class="card-body">
                    <!-- Content for Timesheet Forms -->
                    <div class="loader" *ngIf="loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </div>
                    <div class="reminder mb-0 d-flex bg-light justify-content-between">
                        <h5 class="card-title ps-4 mb-0 tableTitle">Onboarding Forms</h5>
                        <div class="submitForm d-flex justify-content-center align-items-center">
                            <button class="submitForm btn btn-sm px-3 mx-5 btn-neutral d-flex align-items-center text-white"
                                (click)="onBoardFormAdd(false, false)">
                                <i id="add" class="bi bi-plus-circle-dotted me-2"></i>
                                Add New
                            </button>
                        </div>
                    </div>
                    <div class="">
                        <table matSort (matSortChange)="sortOnboardingData($event)" class="table table-hover table-nowrap"
                            id="setting_forms_tables">
                            <thead class="table-light">
                                <tr>
                                    <th mat-sort-header="name" scope="col">Name</th>
                                    <th mat-sort-header="count" scope="col">Fields count</th>
                                    <th class="text-end" scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let form of allBoardingForm; let i = index" class="dataColumn">
                                    <td class="text-heading font-semibold">
                                        <a class="text-heading font-semibold" [routerLink]="['/on-boarding-form/edit', form.uuid]">
                                            {{form.name}}
                                        </a>
                                    </td>
                                    <td>{{ form.count }}</td>
                                    <td class="text-end">
                                        <div class="dropdown">
                                            <a class="text-muted" href="#" role="button" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <i class="bi bi-three-dots-vertical"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end">
                                                <a class="dropdown-item" matTooltip="Edit" [routerLink]="['/on-boarding-form/edit', form.uuid]">
                                                    Edit
                                                </a>
                                                <a class="dropdown-item" matTooltip="View" [routerLink]="['/on-boarding-form/view', form.uuid]">
                                                    View
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
            <app-api-paginator *ngIf="totalCount" [totalCount]="totalCount" (onStepChange)="onStepChange($event)">
            </app-api-paginator>
        </div>
    </div>
</div>
