import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from 'src/app/services/job.service';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { SuccessModal } from 'src/app/app.model';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { roleConstant } from 'src/app/app.constant';
import { JobRejectReasonComponent } from '../job-reject-reason/job-reject-reason.component';

@Component({
  selector: 'app-view-on-boarding-form-submit-details',
  templateUrl: './view-on-boarding-form-submit-details.component.html',
  styleUrls: ['./view-on-boarding-form-submit-details.component.css']
})
export class ViewOnBoardingFormSubmitDetailsComponent implements OnInit {
  @Input() job: any;
  acceptOnBoardingDetails: FormGroup;
  ROLES = roleConstant;
  user: any;
  loading = false;
  error: string;
  signedContract: any; 

  iconList = [
    { type: 'docs', icon: 'assets/extIcons/icons8-microsoft-word-48.png' },
    { type: 'docx', icon: 'assets/extIcons/icons8-microsoft-word-48.png' },
    { type: 'pdf', icon: 'assets/extIcons/icons8-pdf-48.png' },
    { type: 'png', icon: 'assets/extIcons/icons8-png-48.png' },
  ]

  constructor(
    private formBuilder: FormBuilder, 
    private http: HttpClient,
    private activeModalService: NgbActiveModal,
    private jobService: JobService,
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.userValue;
    this.acceptOnBoardingDetails = this.formBuilder.group({
      fields: this.formBuilder.array([])
    });

    this.job.field_data.forEach((field: any) => {
      this.fields.push(this.formBuilder.group({
        label: [field.label],
        value: [field.value],
        type: [field.type],
        attachment: [field.is_attachment ? field.attachment.contents : null],
        is_attachment: [field.is_attachment],
        is_required: [field.is_required],
      }));
    });

    this.signedContract = this.job.contract;

  }

  get fields() {
    return this.acceptOnBoardingDetails.get('fields') as FormArray;
  }

  isImage(fileName: string): boolean {
    const extension = fileName.split('.').pop()?.toLowerCase();
    return extension === 'png' || extension === 'jpg' || extension === 'jpeg' || extension === 'gif';
  }

  isPdf(fileName: string): boolean {
    const extension = fileName.split('.').pop()?.toLowerCase();
    return extension === 'pdf' || extension === 'docx' || extension === 'doc';
  }

  getImageSrc(base64Data: string): string {
    return `data:image/png;base64,${base64Data}`;
  }

  getFileType(fileName: string){
    return fileName.split('.')[fileName.split('.').length-1];
  }

  getFileIcon(attachment: any) {
    const fileType = this.getFileType(attachment);
    return this.iconList.find(x => x.type === fileType)?.icon
  }

  downloadDocument(contents: any, originalName: any) {
    const a = document.createElement('a');
    a.href = "data:image/png;base64," + contents;
    a.setAttribute('download', originalName)
    a.click();
  }

  onClose() {
    this.activeModalService.close();
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  rejectJob(jobUuid: string): void {
    const modalRef = this.modalService.open(JobRejectReasonComponent, {
      size: 'md',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'job-reject-reason-modal',
    });
    modalRef.componentInstance.jobUuid = jobUuid;
    modalRef.componentInstance.routeAction = 'RejectJob';
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.successModal('Job Rejected Successfully.');
        this.activeModalService.close({ refresh: true });
        this.loading = false;
      }
    });
  }

  permanentRejectJob(jobUuid: string) { 
    const modalRef = this.modalService.open(JobRejectReasonComponent, {
      size: 'md',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'job-reject-reason-modal',
    });
    modalRef.componentInstance.jobUuid = jobUuid;
    modalRef.componentInstance.routeAction = 'PermanentRejectJob';
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.successModal('Job Rejected Permanent Successfully.');
        this.activeModalService.close({ refresh: true });
        this.loading = false;
      }
    });
  }

  approveJob(jobUuid: string): void {
    this.jobService.approveJob(jobUuid)
      .subscribe(
        res => {
          this.successModal('Job Approved Successfully.');
          this.activeModalService.close({ refresh: true });
          this.router.navigate(['jobs/active-jobs'])
          this.loading = false;
        }, 
        error => {
          this.loading = false;
          this.error = error;
        }
      );
  }

}
