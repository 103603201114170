import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { roleConstant } from '../../app.constant';
import { EditJobAssignmentComponent } from '../../components/edit-job-assignment/edit-job-assignment.component';
import { CreateTimesheetComponent } from '../../components/create-timesheet/create-timesheet.component';
import { AuthenticationService } from '../../services/authentication.service';
import { DataSharedService } from '../../services/data-shared.service';
import { JobAssignmentService } from '../../services/job-assignment.service';
import { TimesheetService } from '../../services/timesheet.service';
import { SendEmailComponent } from '../../components/send-email/send-email.component';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { SuccessModal } from 'src/app/app.model';
import { SubmitOnboardingFormDetailsComponent } from '../submit-onboarding-form-details/submit-onboarding-form-details.component';

@Component({
  selector: 'app-active-assignments',
  templateUrl: './active-assignments.component.html',
  styleUrls: ['./active-assignments.component.css']
})
export class ActiveAssignmentsComponent implements OnInit {
  allJobAssignments: any = [];
  jobAssignments: any = [];
  user: any;
  ROLES = roleConstant;
  totalCount: number = 0;
  loading: boolean = false;
  redirectedJob!: string;

  constructor(
    private route: ActivatedRoute,
    private jobAssignmentService: JobAssignmentService,
    private timesheetService: TimesheetService,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private modalService: NgbModal,
    public dataSharedService: DataSharedService,
    private activeModalService: NgbActiveModal,
    private router: Router,) { }

  ngOnInit(): void {
    this.user = this.authService.userValue;
    this.route.queryParams.subscribe((params: any) => {
      this.redirectedJob = params?.job;
      this.getJobAssignments(1);
    });

  }

  getJobAssignments(page: any) {
    this.loading = true;
    this.jobAssignments = [];
    this.allJobAssignments = [];
    let filters =  {
      active : 1,
    }
    this.jobAssignmentService.getJobAssignments(page, filters).subscribe((jobassignments) => {
      this.jobAssignments = jobassignments.data;
      if (this.redirectedJob) {
        this.jobAssignments = this.jobAssignments.filter((x: any) => x.job === this.redirectedJob);
      }
      this.totalCount = jobassignments.meta.total;
      this.loading = false;
      this.allJobAssignments = this.jobAssignments.slice();
    });
  }

  
  sortData(sort: Sort) {
    const data = this.jobAssignments.slice();
    if (!sort.active || sort.direction === '') {
      this.allJobAssignments = data;
      return;
    }

    this.allJobAssignments = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'job':
          return compare(a.job, b.job, isAsc);
        case 'candidate':
          return compare(a.candidate, b.candidate, isAsc);
        case 'client':
          return compare(a.client, b.client, isAsc);
        case 'start_date':
          return compare(a.start_date, b.start_date, isAsc);
        case 'end_date':
          return compare(a.end_date, b.end_date, isAsc);
        case 'created_at':
          return compare(a.created_at, b.created_at, isAsc);
        default:
          return 0;
      }
    });
  }

  onStepChange(event: any) {
    this.getJobAssignments(event);
  }

  openSendMessagePopUp(candidateUuid: any) {
    const modalRef = this.modalService.open(SendEmailComponent, {
      size: 'md',
      backdrop: false,
      modalDialogClass: 'add-reminder-modal'
    });

    modalRef.componentInstance.userUuid = candidateUuid;

    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        // this.ngOnInit();
      }
    });
  }

  viewOnBoardingForm(assignment?: any, submitContract: boolean = false) {
    const modalRef = this.modalService.open(SubmitOnboardingFormDetailsComponent, {
      size: 'md',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'fill-Onboarding-form',
    });
    modalRef.componentInstance.formFields = assignment.fields;
    modalRef.componentInstance.contract = assignment.contract;
    modalRef.componentInstance.formDetails = assignment.form_details;
    modalRef.componentInstance.assignmentUuid = assignment.uuid;
    modalRef.componentInstance.isEdit = assignment.is_edit;
    modalRef.componentInstance.submitContract = submitContract;
    modalRef.componentInstance.assignmentStatus = assignment.status;
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {

        this.ngOnInit();
      }
    });
  }

  onEdit(assignment?: any) {
    const modalRef = this.modalService.open(EditJobAssignmentComponent, {
      size: 'md',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'edit-job-assignment-modal',
    });
    modalRef.componentInstance.assignment = assignment
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        //this.getJobAssignments(1);
        this.ngOnInit();
      }
    });
  }

  createTimesheet(assignment?: any) {
    const modalRef = this.modalService.open(CreateTimesheetComponent, {
      size: 'md',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'add-reminder-modal',
    });
    modalRef.componentInstance.assignment = assignment
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.ngOnInit();
      }
    });
  }

  inactivateAssignment(assigmentUuid: string) {
    this.jobAssignmentService.deactivateAssignment(assigmentUuid).subscribe({
      next: (response) => {
          this.activeModalService.close({
            data: true,
          });
          this.successModal('Deactivated Successfully.');
          this.router.navigate(['assignments/inactive-assignments'])
      },
      error: (err) => {
      }
    });
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
