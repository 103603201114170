import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from 'src/app/services/job.service';
import { SuccessModal } from 'src/app/app.model';
import { SuccessModalComponent } from '../success-modal/success-modal.component';

@Component({
  selector: 'app-job-reject-reason',
  templateUrl: './job-reject-reason.component.html',
  styleUrls: ['./job-reject-reason.component.css']
})
export class JobRejectReasonComponent implements OnInit {
  @Input() jobUuid: any;
  @Input()  routeAction: string; 
  loading: boolean = false;
  error!: string;
  reason: string = ''; 

  constructor(
    public activeModalService: NgbActiveModal,
    private modalService: NgbModal,
    private jobService: JobService,
  ) { }

  ngOnInit(): void {
  }

  onClose() {
    this.activeModalService.close();
   
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  reject(): void {
    const payload = { reason: this.reason };
    const action = (this.routeAction === 'PermanentRejectJob') ? 'permanentRejectJob' : 'rejectJob';

    this.jobService[action](this.jobUuid, payload)
      .subscribe(
        res => {
          this.activeModalService.close({ refresh: true });
          this.loading = false;
        }, 
        error => {
          this.loading = false;
          this.error = error;
        }
      );

  }

}
