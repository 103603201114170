<header>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-sm col-12 d-flex">
                <h4 class="modal-title fs-5">View Onboarding Form</h4>
                <ng-container>
                    <a [routerLink]="['/on-boarding-form', 'edit', formData.uuid]" class="btn btn-sm mx-2 btn-success">Edit Form</a>
                </ng-container>                  
            </div>
            <div class="col-sm-auto col-12 mt-4 mt-sm-0">
                <button type="button" class="btn-close btn-neutral btn-square" aria-label="Close"
                    (click)="close()"></button>
            </div>
        </div>
    </div>
</header>

<main class="py-6 bg-surface-secondary">
    <div class="container-fluid max-w-screen-md vstack gap-6">
        <div class="vstack gap-5">
            <div class="" [ngClass]="preview ? 'previewFormEnabled':''">

                <div class="example-list">
                    <!-- Form preview -->
                    <div class="card example-box p-5">
                        <div class="">
                            <input class="form-control" placeholder="{{ previewData.form_name }}" type="text" disabled>
                        </div>
                    </div>
                    <!-- Iterate over preview data -->
                    <div class="card example-box p-5" *ngFor="let field of previewData.fields">
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-md-6">
                                    <input type="text" class="form-control" placeholder="Name: {{ field.label }}"
                                        readonly>
                                </div>
                                <div class="col-md-6">
                                    <ng-container *ngIf="field.type === 'dropdown'">
                                        <select class="form-control" [disabled]="false">
                                            <ng-container *ngIf="field.values">
                                                <option *ngFor="let option of field.values.split(',')" [value]="option">{{ option }}</option>
                                            </ng-container>
                                            <ng-container *ngIf="field.options">
                                                <option *ngFor="let option of field.options" [value]="option.name">{{ option.name }}</option>
                                            </ng-container>
                                        </select>
                                    </ng-container>
                                    <ng-container *ngIf="field.type !== 'dropdown'">
                                        <input type="text" class="form-control" placeholder="Type: {{ field.type }}" readonly>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-section-footer">
                                        <div class="requiredFlag">
                                            <span>Required</span>
                                            <input type="checkbox" [checked]="field.is_required" readonly>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Additional controls for preview -->
                    <div class="card example-box p-5" *ngIf="selectedFile">
                        <div class="subBody">
                            <div class="right">
                                <div class="box">
                                    <img class="imageIcon" *ngIf="getFileIcon(selectedFile.name)" [src]="getFileIcon(selectedFile.name)" />
                                </div>
                                <div class="textarea">
                                    <div class="nameText mt-4">
                                        <label>{{ selectedFile.name }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>