import { Location } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OnBoardingFormService } from 'src/app/services/on-boarding-form.service';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { SuccessModal } from '../../app.model';

@Component({
  selector: 'app-create-on-boarding-form',
  templateUrl: './create-on-boarding-form.component.html',
  styleUrls: ['./create-on-boarding-form.component.css']
})

export class CreateOnBoardingFormComponent implements OnInit {
  formName!: string;
  error = '';
  fields: any = [
    { is_required: 0, type: 'text', order: 7, label: '' }
  ]
  preview: boolean = false;
  view: boolean = false;
  fromEdit: boolean = false;
  formData: any;
  loading: boolean = false;
  formCount: any = 0;
  previewData: any = {};
  contractCheckbox: boolean = false;
  showFileField: boolean = false;
  selectedFile: File | null = null;
  acceptFiles: any = [
    '.pdf', '.docx', '.doc'
  ]

  iconList = [
    { type: 'doc', icon: 'assets/extIcons/icons8-microsoft-word-48.png' },
    { type: 'docx', icon: 'assets/extIcons/icons8-microsoft-word-48.png' },
    { type: 'pdf', icon: 'assets/extIcons/icons8-pdf-48.png' },
  ]

  constructor(
    private onBoardingFormService: OnBoardingFormService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private modalService: NgbModal,
    private activeModalService: NgbActiveModal,
  ) {

    if (this.route.snapshot.queryParams['preview']) {
      this.preview = JSON.parse(this.route.snapshot.queryParams['preview']);
      this.view = this.preview;
      this.fromEdit = !this.preview;
    }
    
  }

  ngOnInit(): void {
    if (this.formData && !this.preview) { 
      this.fetchForm()
    }

    if(this.preview){
      this.previewData = { 
        form_name: this.formData.name,
        fields: this.formData.fields,
        
      };

      if (this.formData?.document) {
        this.contractCheckbox = true; 
        this.showFileField = true;
        this.selectedFile = this.formData.document; 
      }

    }
  }

  close() {
    this.location.back();
  }

  getFileType(fileName: string){
    return fileName.split('.')[fileName.split('.').length-1];
  }

  getFileIcon(attachment: any) {
    const fileType = this.getFileType(attachment);
    return this.iconList.find(x => x.type === fileType)?.icon
  }

  toggleFileField() {
    this.showFileField = !this.showFileField;

    if (this.fromEdit && this.formData?.document) {
      return;
    }
    
    if (!this.showFileField ) {
        this.selectedFile = null;
    }

  }

  changeForm(form_section: boolean) {
    this.preview = form_section;
    this.fromEdit = !this.preview;

    if (this.preview) {
      this.generatePreviewData();
    }

    console.log("changeForm this.preview -------", this.preview);
    console.log("changeForm this.fromEdit -------", this.fromEdit);
  }

  generatePreviewData() {
    this.previewData = {
      form_name: this.formName,
      fields: this.fields
    };
  }

  fetchForm() {
    this.fields = [];
    this.formName = this.formData.name;
    this.formData.fields.forEach((element: any) => {
      var field = element;
      field.is_required = field.is_required ? true : false;
      if (element.type === 'dropdown' && element.values) {
        field.options = [];
        const options = element.values.split(',')
        options.forEach((option: any) => {
          field.options.push({ name: option });
        });
      } 
      this.fields.push(field);
    });
    this.formCount = this.fields.length;

    // Check if the document is available
    if (this.formData?.document) {
      this.contractCheckbox = true; 
      this.showFileField = true;
      this.selectedFile = this.formData.document; 
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.fields, event.previousIndex, event.currentIndex);
    this.fields.forEach((element: any, i: number) => {
      element.order = i;
    });
  }

  addField() {
    this.fields.push({ is_required: 0, type: 'text', order: this.fields.length, label: '' })
  }

  removeField(i: number) {
    this.fields.splice(i, 1)
  }

  onTypeChange(event: any, i: number) {
    if (event.target.value === 'dropdown') {
      this.fields[i].options = [{ name: 'Option 1' }];
    } else {
      delete this.fields[i].options
    }
  }

  addOption(i: number) {
    this.fields[i].options.push({ name: `Option ${this.fields[i].options.length + 1}` });
  }

  removeOption(i: number, optionIdx: number) {
    this.fields[i].options.splice(optionIdx, 1);
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  isOptionsValid(options: any[]): boolean {
    let valid = true;
    options?.forEach(element => {
      if (!element?.name) {
        valid = false;
      }
    });
    return valid
  }

  get isValid(): boolean {
    let valid = true;

    if(this.fields.length > 1) {
      this.fields.forEach((singleField: any) => {
        if (((singleField.type === 'dropdown' && !singleField?.options?.length) || (!this.isOptionsValid(singleField.options))) || (singleField.type !== 'dropdown' && singleField.type !== 'readonly' && !singleField.label)) {
          valid = false;
        }
      });
    } else if(this.fields.length == 1 && this.fields[0].label) { 
      this.fields.forEach((singleField: any) => {
          if (((singleField.type === 'dropdown' && !singleField?.options?.length) || (!this.isOptionsValid(singleField.options))) || (singleField.type !== 'dropdown' && singleField.type !== 'readonly' && !singleField.label)) {
            valid = false;
          }
      });
    }

    return valid && (this.formName ? true : false);
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  submitForm() {
    this.error = '';
    if (!this.isValid) {
      this.error = 'Please fill all the fields';
      return;
    }
    const fieldsWithLabels = this.fields.filter((field:any) => field.label);

    if (!this.contractCheckbox && fieldsWithLabels.length === 0) {
      this.error = 'Please select a contract PDF file or add at least one field';
      this.loading = false;
      return;
    }

    if (this.contractCheckbox && !this.selectedFile) {
      this.error = 'Please select a PDF file';
      return;
    }

    this.loading = true;

    // Create FormData object and append form data
    const formData = new FormData();

    if (this.contractCheckbox && this.selectedFile) {
        formData.append('pdf_file', this.selectedFile);
    } 
    
    if (fieldsWithLabels.length > 0) {
      this.fields.forEach((singleForm: any) => {
        singleForm.is_required = singleForm.is_required ? 1 : 0;
  
        if (singleForm.type === 'dropdown') {
          singleForm.values = ''
          singleForm.options.forEach((singleOption: any) => {
            if (singleForm.values) {
              singleForm.values += ',' + singleOption.name;
            } else {
              singleForm.values = singleOption.name;
            }
          });
          delete singleForm.options;
        } else {
          singleForm.values = '';
        }
      });

      formData.append('form_fields', JSON.stringify(this.fields));
    } 

    formData.append('form_name', this.formName); 
    formData.append('is_pdf_file', this.contractCheckbox ? '1' : '0');

    this.onBoardingFormService.submitOnBoardingForm(formData, this.formData?.uuid)
    .subscribe(
      res => {
        this.successModal(res.message ? res.message : 'Form Data Submitted Successfully.');
        this.router.navigate(['/settings/form']);
        this.loading = false;
      },
      err => {
        console.error(err);
        this.loading = false;
      }
    );

  }

}
