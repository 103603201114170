<header>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-sm col-12 d-flex">
                <h4 class="modal-title fs-5">{{ view ? 'View' : (preview ? 'Preview' : (formData ? 'Edit' : 'Create')) }} Onboarding Form</h4>
                <ng-container *ngIf="view===false">
                    <button type="button" class="btn btn-sm mx-2 btn-success" (click)="changeForm(fromEdit)"
                    *ngIf="preview">{{preview
                    ?'Edit Form':'Preview Form'}}</button>
                    
                </ng-container>
                
            </div>
            <div class="col-sm-auto col-12 mt-4 mt-sm-0">
                <button type="button" class="btn-close btn-neutral btn-square" aria-label="Close"
                    (click)="close()"></button>
            </div>
        </div>
    </div>
</header>

<main class="py-6 bg-surface-secondary" *ngIf="!preview">
    <div class="container-fluid max-w-screen-md vstack gap-6">
        <div class="vstack gap-5">
            <div class="" [ngClass]="preview ? 'previewFormEnabled':''">
                <div cdkDropList (cdkDropListDropped)="drop($event)" class="example-list">
                    <div class="card example-box p-5">
                        <div class="">
                            <input class="form-control" placeholder="Form Name" type="text" [(ngModel)]="formName">
                        </div>
                    </div>

                    <div cdkDrag class="card" *ngFor="let field of fields; let i = index"
                        [style.display]="preview ? 'none':''">
                        <div class="card-body">
                            <div class="row g-3">
                                <div class="col-md-7">
                                    <div class="d-flex">
                                        <div class="d-flex justify-content-between align-items-center me-2"
                                            cdkDragHandle>
                                            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                            <div class="dragIcon">
                                                <mat-icon>drag_indicator</mat-icon>
                                            </div>
                                        </div>
                                        <div class="w-100">
                                            <div class="mb-2">
                                                <input type="text" class="form-control" placeholder="Name"
                                                    [(ngModel)]="field.label">
                                            </div>
                                            <div class="mb-2 d-flex">
                                                <div class="dropdown-values w-100" *ngIf="field.type === 'dropdown'">
                                                    <div class="d-flex mb-2"
                                                        *ngFor="let option of field?.options; let optionIdx = index">
                                                        <input type="text" class="form-control" placeholder="Add option"
                                                            [(ngModel)]="option.name" />

                                                        <i class="bi bi-x ms-2"
                                                            *ngIf="optionIdx !== 0 || fields[i].options.length !== 1"
                                                            (click)="removeOption(i, optionIdx)"></i>

                                                        <div class="transparent"
                                                            *ngIf="optionIdx === 0 && fields[i].options.length === 1">
                                                        </div>
                                                    </div>
                                                    <div class="d-flex w-100" (click)="addOption(i)">
                                                        <input type="text" class="form-control"
                                                            placeholder="Add option" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="mb-2 d-flex">
                                        <select class="form-select" [(ngModel)]="field.type"
                                            (change)="onTypeChange($event, i)">
                                            <option value="text">Text</option>
                                            <option value="textarea">Text Area</option>
                                            <option value="number">Number</option>
                                            <option value="dropdown">Dropdown</option>
                                            <option value="file">Upload file (Pdf/Image)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-section-footer">
                                        <i class="bi bi-trash3" (click)="removeField(i)"></i>
                                        <div class="requiredFlag">
                                            <span>Required</span>
                                            <input type="checkbox" [(ngModel)]="field.is_required"
                                                [ngModelOptions]="{standalone: true}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card example-box">
                        <div class="card-body">
                            <div class="row g-3">
                                <div class="col-md-5">
                                    <div class="mb-2 d-flex align-items-center">
                                        <input type="checkbox" id="contractCheckbox" [(ngModel)]="contractCheckbox"
                                            (change)="toggleFileField()">
                                        <label for="contractCheckbox" class="form-label mb-0 ms-2">Contract
                                            Checkbox</label>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2 d-flex" *ngIf="showFileField ">
                                <input type="file" [accept]="acceptFiles.join(',')" class="form-control" (change)="onFileSelected($event)">
                            </div>
                            <div class="mt-4 d-flex" *ngIf="selectedFile && showFileField">
                                <div class="right">
                                    <div class="box">
                                        <img class="imageIcon" *ngIf="getFileIcon(selectedFile.name)" [src]="getFileIcon(selectedFile.name)" />
                                    </div>
                                    <div class="textarea">
                                        <div class="nameText mt-4">
                                            <label>{{ selectedFile.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                        </div>
                    </div>

                    <div class="error">
                        <span *ngIf="error">{{error}}</span>
                    </div>
                    <div class="loader" *ngIf="loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </div>
                    <div class="submitForm mt-10">
                        <button type="button" class="btn btn-sm mx-2 btn-success"
                            (click)="changeForm(fromEdit)">{{preview
                            ?'Back':'Preview Form'}}</button>
                        <button type="button" class="btn btn-sm mx-2 btn-primary" (click)="submitForm()"
                            [disabled]="loading">Submit</button>
                        <button type="button" class="btn btn-sm mx-2 btn-secondary" (click)="addField()">Add New
                            Section</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<main class="py-6 bg-surface-secondary" *ngIf="preview && previewData">
    <div class="container-fluid max-w-screen-md vstack gap-6">
        <div class="vstack gap-5">
            <div class="" [ngClass]="preview ? 'previewFormEnabled' : ''">
                <div class="example-list">
                    <div class="card example-box p-5">
                        <div class="">
                            <input class="form-control" placeholder="{{ previewData.form_name }}" type="text" disabled>
                        </div>
                    </div>
                    <div class="card example-box p-5" *ngFor="let field of previewData.fields">
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-md-6">
                                    <input type="text" class="form-control" placeholder="Name: {{ field.label }}"
                                        disabled>
                                </div>
                                <div class="col-md-6">
                                    <ng-container *ngIf="field.type === 'dropdown'">
                                        <select class="form-control" [disabled]="false">
                                            <ng-container *ngIf="field.values">
                                                <option *ngFor="let option of field.values.split(',')" [value]="option">{{ option }}</option>
                                            </ng-container>
                                            <ng-container *ngIf="field.options">
                                                <option *ngFor="let option of field.options" [value]="option.name">{{ option.name }}</option>
                                            </ng-container>
                                        </select>
                                    </ng-container>
                                    <ng-container *ngIf="field.type !== 'dropdown'">
                                        <input type="text" class="form-control" placeholder="Type: {{ field.type }}" disabled>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-section-footer">
                                        <div class="requiredFlag">
                                            <span>Required</span>
                                            <input type="checkbox" [checked]="field.is_required" disabled>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card example-box p-5" *ngIf="selectedFile">
                        <div class="subBody">
                            <div class="right">
                                <div class="box">
                                    <img class="imageIcon" *ngIf="getFileIcon(selectedFile.name)" [src]="getFileIcon(selectedFile.name)" />
                                </div>
                                <div class="textarea">
                                    <div class="nameText mt-4">
                                        <label>{{ selectedFile.name }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>