<div class="loader" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
<div class="modal-header">
    <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
    <h4 class="modal-title">Edit Job</h4>
    <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>

<form class="" [formGroup]="jobForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <div class="row gx-4">
            <div class="col-md-6">
                <label class="form-label">Name</label>
                <input type="text" class="form-control" formControlName="name"
                    [id]="jobForm.controls.name.errors && jobForm.controls.name.touched?'invalidField':''">
            </div>
            <div class="col-md-6">
                <label class="form-label">Description (Optional)</label>
                <input type="text" class="form-control" formControlName="description"
                    [id]="jobForm.controls.description.errors && jobForm.controls.description.touched?'invalidField':''">
            </div>
            <div class="col-md-6">
                <label class="form-label">Location (Optional)</label>
                <input type="text" class="form-control" formControlName="location"
                    [id]="jobForm.controls.location.errors && jobForm.controls.location.touched?'invalidField':''">
            </div>
            <div class="col-md-6">
                <label class="form-label">Positions (Optional)</label>
                <input type="number" class="form-control" formControlName="positions"
                    [id]="jobForm.controls.positions.errors && jobForm.controls.positions.touched?'invalidField':''">
            </div>
            <div class="col-md-6">
                <label class="form-label">Pay Rate</label>
                <input type="number" class="form-control" formControlName="pay_rate"
                    [id]="jobForm.controls.pay_rate.errors && jobForm.controls.pay_rate.touched?'invalidField':''">
            </div>
            <div class="col-md-6">
                <label class="form-label">Bill Rate</label>
                <input type="number" class="form-control" formControlName="bill_rate"
                    [id]="jobForm.controls.bill_rate.errors && jobForm.controls.bill_rate.touched?'invalidField':''">
            </div>
            <div class="col-md-12 ">
                <label class="form-label">Client</label>
                <input formControlName="client" style="display: none;">
                <input type="text" class="form-control" formControlName="client" readonly />
            </div>
            <div class="col-md-12 ">
                <label for="selectTimeSheet">Timesheet Approvers</label>
                <mat-select class="me-2 input-form" multiple  formControlName="timesheet_approvers" [(ngModel)]="selectedTimesheetApprovers">
                    <mat-option *ngFor="let timesheetApprover of timesheetApprovers; let i = index;" [value]="timesheetApprover.uuid">{{timesheetApprover.name}}
                    </mat-option>
                </mat-select>	
            </div>
            <div class="col-md-6 full clientSelect">
                <label class="form-label">Candidates</label>
                <input formControlName="client" style="display: none;">
                <input type="text" class="form-control" formControlName="candidates" readonly />
            </div>
            <div class="col-md-6 full onBoardingFormSelect">
                <label class="form-label">Onboarding Form</label>
                <input formControlName="on_boarding_form" style="display: none;">
                <input type="text" class="form-control" formControlName="on_boarding_form" readonly />
            </div>
            <div class="col-md-6">
                <label class="form-label">Start Date - End Date</label>
                <div class="datePickerRangeContainer" (click)="picker.open()"
                    [id]="(jobForm.controls.start_date.errors && jobForm.controls.start_date.touched?'invalidField':'') || (jobForm.controls.end_date.errors && jobForm.controls.end_date.touched?'invalidField':'')">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker color="primary"></mat-date-range-picker>
                    <i class="bi bi-calendar4"></i>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="start_date">
                        <input matEndDate formControlName="end_date">
                    </mat-date-range-input>
                </div>
            </div>
            <div *ngIf="job.status !== 5">
                <div class="col-md-6 mt-2 d-flex align-items-center">
                    <ng-container *ngIf="sendContract; else elseBlock">
                        <input type="checkbox" class="custom-checkbox" formControlName="is_checked"  [checked]="sendContract" [attr.disabled]="sendContract">
                    </ng-container>
                    <ng-template #elseBlock>
                        <input type="checkbox" class="custom-checkbox" formControlName="is_checked" [checked]="job.contract !== null" [attr.disabled]="job.contract !== null ? true : null">
                    </ng-template>
                    <label class="form-label ms-2 mb-0"> Add Contract</label>
                </div>
                <div *ngIf="job.contract == null && jobForm.get('is_checked').value" class="col-md-12 mt-2">
                    <input type="file" class="form-control" [accept]="acceptFiles.join(',')" formControlName="contract_file" (change)="onFileSelected($event)" [id]="jobForm.controls.contract_file.errors && jobForm.controls.contract_file.touched?'invalidField':''">
                </div>

                <div *ngIf="job.contract && job.contract.is_separate_contract" class="mt-4">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <div class="box">
                                <img class="imageIcon" *ngIf="getFileIcon(job.contract.name)" [src]="getFileIcon(job.contract.name)" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="textarea">
                                <div class="nameText mt-4">
                                    <label>{{ job.contract.name }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="error" *ngIf="error">
            <span>{{error}}</span>
        </div>
        <button type="submit" class="btn btn-sm mx-2 btn-primary" [disabled]="isSaveDisabled()">Save</button>

        <!-- <button type="submit" class="btn btn-sm mx-2 btn-primary" [disabled]=" !jobForm.valid || loading">Save</button> -->
    </div>   
</form>