import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { roleConstant } from '../app.constant';
import { CreateJobAssignmentComponent } from '../components/create-job-assignment/create-job-assignment.component';
import { CreateTimesheetComponent } from '../components/create-timesheet/create-timesheet.component';
import { DataSharedService } from '../services/data-shared.service';
import { JobAssignmentService } from '../services/job-assignment.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-job-assignments',
  templateUrl: './job-assignments.component.html',
  styleUrls: ['./job-assignments.component.css'],
})

export class JobAssignmentsComponent implements OnInit {
  allJobAssignments: any = [];
  jobAssignments: any = [];
  user: any;
  ROLES = roleConstant;
  totalCount: number = 0;
  loading: boolean = false;
  redirectedJob!: string;

  constructor(
    private jobAssignmentService: JobAssignmentService,
    public dialog: MatDialog,
    private modalService: NgbModal,
    public dataSharedService: DataSharedService,
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.userValue;
  }

  createTimesheet(assignment?: any) {
    const modalRef = this.modalService.open(CreateTimesheetComponent, {
      size: 'md',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'add-reminder-modal',
    });
    modalRef.componentInstance.assignment = assignment
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.ngOnInit();
      }
    });
  }

  getJobAssignments(page: any) {
    this.loading = true;
    this.jobAssignments = [];
    this.allJobAssignments = [];
    this.jobAssignmentService.getJobAssignments(page).subscribe((jobassignments) => {
      this.jobAssignments = jobassignments.data;
      if (this.redirectedJob) {
        this.jobAssignments = this.jobAssignments.filter((x: any) => x.job === this.redirectedJob);
      }
      this.totalCount = jobassignments.meta.total;
      this.loading = false;
      this.allJobAssignments = this.jobAssignments.slice();
    });
  }

  onAdd() {
    const modalRef = this.modalService.open(CreateJobAssignmentComponent, {
      size: 'md',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'create-job-assignment-modal',
    });
    modalRef.result.then((data) => {
      if (data?.refresh === true) {
        this.getJobAssignments(1);
      }
    });
  }
  
}