<div class="loader" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
<div class="modal-header">
    <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
    <h4 class="modal-title">Fill Onboarding Form Details</h4>
    <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>

<form class="" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <h3 class="text-center">{{ formDetails.name }}</h3>
  
        <div class="row gx-4">
            <div *ngFor="let field of formFields" class="col-md-12">
                <label class="form-label mt-4">{{ field.label }}{{ field.is_required ? '*' : '' }}</label>
                <input *ngIf="field.type === 'text'" type="text" class="form-control" [formControlName]="field.field_uuid"
                    [id]="getFieldErrorId(field.field_uuid)" value="field.value" [attr.disabled]="isInputFieldDisabled ? true : null">
                <input *ngIf="field.type === 'number'" type="number" class="form-control" [formControlName]="field.field_uuid"
                    [id]="getFieldErrorId(field.field_uuid)" value="field.value" (keypress)="onKeyPress($event)" [attr.disabled]="isInputFieldDisabled ? true : null">
                <textarea *ngIf="field.type === 'textarea'" class="form-control" [formControlName]="field.field_uuid"
                    [id]="getFieldErrorId(field.field_uuid)" [attr.disabled]="isInputFieldDisabled ? true : null"></textarea>
                <select *ngIf="field.type === 'dropdown'" class="form-select" [formControlName]="field.field_uuid"
                    [id]="getFieldErrorId(field.field_uuid)" [attr.disabled]="isInputFieldDisabled ? true : null">
                    <option *ngFor="let option of field.options.split(',')" [value]="option.trim()">{{ option.trim() }}</option>
                </select>
                <input *ngIf="field.type === 'file'" type="file" class="form-control" [accept]="acceptFiles.join(',')" [formControlName]="field.field_uuid"
                    [id]="getFieldErrorId(field.field_uuid)" (change)="onFileChange($event, field.field_uuid)" [attr.disabled]="isInputFieldDisabled ? true : null">
                
                <ng-container *ngIf="field && field.type === 'file' && field.contents">
                    <div *ngIf="isPdf(field.value)" class="pdf-container">
                        <div class="d-flex align-items-center mt-2">
                            <div class="me-2">
                                <img class="imageIcon" *ngIf="getFileIcon(field.value)" [src]="getFileIcon(field.value)" />
                        
                            </div>
                            <div class="flex-grow-1">
                                <label>{{ field.value }}</label>
                            </div>
                            <div class="ms-auto">
                                <button type="button" (click)="downloadDocument(field.value, field.contents)" class="btn btn-sm mx-2 btn-success">
                                    <i class="bi bi-cloud-arrow-down-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <img *ngIf="isImage(field.value)" [src]="getImageSrc(field.contents)" alt="Attachment" class="attachment-img d-flex align-items-center mt-4">
                    
                </ng-container>  
                
                <div *ngIf="form.controls[field.field_uuid].invalid && form.controls[field.field_uuid].touched"
                    class="invalid-feedback">
                    Please provide a valid {{ field.label.toLowerCase() }}.
                </div>
            </div>
            <div class="col-md-12 mt-4" *ngIf="contract">
                <label class="form-label">Contract Document</label>
                <div class="d-flex align-items-center">
                    <div class="box me-3">
                        <img class="imageIcon" src="assets/extIcons/icons8-pdf-48.png" />
                    </div>
                    <div class="textarea">
                        <div class="nameText mt-4">
                            <label>{{ contract.name }}</label>
                        </div>
                    </div>
                    <div class="smallBox ms-auto">
                        <button class="btn btn-sm mx-2 btn-success" type="button" (click)="downloadDocument(contract.name, contract.original_doc_contents)">
                            <i class="bi bi-cloud-arrow-down-fill"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-md-12 mt-4" *ngIf="contract">
                <label class="form-label">Upload Signed Contract</label>
                <input type="file" class="form-control" (change)="onFileChange($event, 'contract')" [accept]="acceptContractFiles.join(',')" />

            </div>
            
        </div>
    </div>
    <div class="modal-footer">
        <div class="error" *ngIf="error">
            <span>{{ error }}</span>
        </div>
        <button *ngIf="isEdit === true " type="submit" class="btn btn-sm mx-2 btn-primary" 
        [disabled]="(!form.valid || loading) || (formFields.length === 0 && !isContractUploaded )">{{ isInputFieldDisabled ? 'Save' : 'Resubmit' }}</button>
        <button *ngIf="isEdit === false " type="submit" class="btn btn-sm mx-2 btn-primary" 
        [disabled]="(!form.valid || loading) || (formFields.length === 0 && !isContractUploaded )">Save</button>
        
    </div>
</form>

  

