import { Component, OnInit } from '@angular/core';
import { auditTrailHeader } from '../../app.model';
import { AuditTrailComponent } from '../../components/audit-trail/audit-trail.component';
import { AuthenticationService } from '../../services/authentication.service';
import { DataSharedService } from '../../services/data-shared.service';
import { JobService } from '../../services/job.service'
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { roleConstant } from '../../app.constant';
import { Sort } from '@angular/material/sort';
import { EditJobComponent } from '../../components/edit-job/edit-job.component';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { SuccessModal } from 'src/app/app.model';
import { ViewOnBoardingFormSubmitDetailsComponent } from '../view-on-boarding-form-submit-details/view-on-boarding-form-submit-details.component';

@Component({
  selector: 'app-inactive-jobs',
  templateUrl: './inactive-jobs.component.html',
  styleUrls: ['./inactive-jobs.component.css']
})
export class InactiveJobsComponent implements OnInit {

  ROLES = roleConstant;
  user: any;
  allJobs: any = [];
  jobs: any = [];
  totalCount: number = 0;
  redirectedName!: string;
  loading: boolean = false;
  jobSearch: string = '';
  constructor(private jobService: JobService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    public dataSharedService: DataSharedService,
    private activeModalService: NgbActiveModal,
    private router: Router,) { }

  ngOnInit(page=1): void {
    this.route.queryParams.subscribe((params: any) => {
      this.redirectedName = params?.name;
      this.getJobs(page);
    });
  }

  async getJobs(page: number, search?: string) {
    this.jobs = [];
    this.allJobs = [];
    this.user = this.authService.userValue;
    this.loading = true;
    let filters =  {
      active : 0,
    }
    this.jobService.getJobs(page, search, filters).subscribe(jobs => {
      this.jobs = jobs.data;
      this.totalCount = jobs.meta.total;
      if (this.redirectedName) {
        this.jobs = this.jobs.filter((x: any) => x.title === this.redirectedName);
      }
      this.loading = false;
      this.allJobs = this.jobs.slice();
    });
  }

  searchJob() {
    if (this.jobSearch != '') {
      this.getJobs(1, this.jobSearch);
    } else {
      this.getJobs(1);
    }
  }

  sortData(sort: Sort) {
    const data = this.jobs.slice();
    if (!sort.active || sort.direction === '') {
      this.allJobs = data;
      return;
    }

    this.allJobs = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'title':
          return compare(a.name, b.name, isAsc);
        case 'description':
          return compare(a.description, b.description, isAsc);
        case 'pay_rate':
          return compare(a.pay_rate, b.pay_rate, isAsc);
        case 'Positions':
          return compare(a.Positions, b.Positions, isAsc);
        case 'location':
          return compare(a.location, b.location, isAsc);
        case 'start_date':
          return compare(a.start_date, b.start_date, isAsc);
        case 'end_date':
          return compare(a.end_date, b.end_date, isAsc);
        case 'created_at':
          return compare(a.created_at, b.created_at, isAsc);
        default:
          return 0;
      }
    });
  }

  openAuditTrail(job: any) {
    let modalRef = this.dialog.open(AuditTrailComponent, {
      data: {
        uuid: job.uuid,
        isIcon: true,
        type: 'job',
        header: <auditTrailHeader>{
          title: job.title,
          subTitle: `${job.start_date} - ${job.end_date}`,
          icon: 'bi bi-briefcase',
        }
      },
    });

    modalRef.afterClosed().subscribe((result: any) => {
      if (result?.refresh) {
        this.ngOnInit();
      }
    });
  }

  onStepChange(event: any) {
    this.ngOnInit(event)
  }

  viewOnBoardingFormDetails(job: string) { 
    const modalRef = this.modalService.open(ViewOnBoardingFormSubmitDetailsComponent, {
      size: 'md',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'view-submit-on-boarding-form-modal',
    });
    modalRef.componentInstance.job = job
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.ngOnInit();
      }
    });
  }

  activateJob(jobUuid: string, isActiveJobOnce: boolean) {
    this.jobService.activateJob(jobUuid).subscribe({
      next: (response) => {
          this.activeModalService.close({
            data: true,
          });
          this.successModal('Activated Successfully.');
          if(isActiveJobOnce == true) {
            this.router.navigate(['jobs/active-jobs']);
          } else {
            this.router.navigate(['jobs/request-jobs'])
          }
          
      },
      error: (err) => {
      }
    });
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
