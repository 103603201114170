import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs';
import { roleConstant } from '../app.constant';
import { auditTrailHeader } from '../app.model';
import { AuditTrailComponent } from '../components/audit-trail/audit-trail.component';
import { CreateJobComponent } from '../components/create-job/create-job.component';
import { AuthenticationService } from '../services/authentication.service';
import { DataSharedService } from '../services/data-shared.service';
import { JobService } from '../services/job.service';
import { EditJobComponent } from '../components/edit-job/edit-job.component';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {
  ROLES = roleConstant;
  user: any;
  allJobs: any = [];
  jobs: any = [];
  totalCount: number = 0;
  redirectedName!: string;
  loading: boolean = false;
  jobSearch: string = '';

  constructor(
    private jobService: JobService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    public dataSharedService: DataSharedService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.userValue;
  }

  deleteJob(jobId: string) {
    if (confirm('Are you sure')) {
      this.jobService.deleteJob(jobId).subscribe(res => {
        this.ngOnInit();
      })
    }
  }

  createJob() {
    const modalRef = this.modalService.open(CreateJobComponent, {
      size: 'md',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'add-client-modal',
    });
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.ngOnInit();
      }
    });
  }
}